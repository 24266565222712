<template>
  <div>
    <Hader />
    <div class="op"></div>
    <div class="bodyy clear">
      <div class="w">
        <el-button
          @click="drawer = true"
          type="primary"
          style="margin-left: 16px;"
          class="dispyspu"
        >
          <i class="el-icon-s-unfold"></i>
          菜单
        </el-button>
        <el-drawer
          :visible.sync="drawer"
          :with-header="false"
          :direction="direction"
          style="width: 135%;"
        >
          <div class="menu-le">
            <router-link to="/user/information"
              ><span class="ger">个人资料</span></router-link
            >
            <el-divider></el-divider>
            <router-link to="/user/profile"
              ><span class="ger">密码修改</span></router-link
            >
            <el-divider></el-divider>
            <router-link to="/user/mtravels"
              ><span class="ger">我的游记</span></router-link
            >
            <el-divider></el-divider>
            <router-link to="/user/writeadd"
              ><span class="ger">我要写游记</span></router-link
            >
            <el-divider></el-divider>
          </div>
        </el-drawer>
        <div class="menu-left">
          <router-link to="/user/information"
            ><span class="ger">个人资料</span></router-link
          >
          <el-divider></el-divider>
          <router-link to="/user/profile"
            ><span class="ger">密码修改</span></router-link
          >
          <el-divider></el-divider>
          <router-link to="/user/mtravels"
            ><span class="ger">我的游记</span></router-link
          >
          <el-divider></el-divider>
          <router-link to="/user/writeadd"
            ><span class="ger">我要写游记</span></router-link
          >
          <el-divider></el-divider>
        </div>
        <div class="menu-right">
          <transition name="component-fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Hader from "@/components/user/Hader";
export default {
  created() {
    this.active = this.$route.path;
  },
  watch: {
    $route: {
      handler: function(val, oldVal) {
        this.active = val.path;
        this.drawer = false;
      },
      // 深度观察监听
      deep: true,
    },
  },
  components: {
    Hader,
  },
  data() {
    return {
        direction: "ltr",
      drawer: false,
      active: "/user/information",
    };
  },
};
</script>
<style lang="scss" scoped>
.op {
  height: 1px;
  background-color: #dbdbdb;
}
.menu-le {
  width: 220px;
  height: 254px;
  background-color: #ffffff;
  padding-top: 16px;
  .ger {
    font-size: 16px;
    margin-left: 22px;
    &:hover {
      color: #6EB400;
    }
  }
  .el-divider--horizontal {
    width: 86%;
    margin: 20px 13px;
    background-color: #d9d9d9;
  }
}
.dispyspu{
  display: none;
}
.bodyy {
  background-color: #dbdbdb29;
  background-image: url("~@/assets/image/main/shan1.png");
  background-repeat: no-repeat;
  background-position: 0% 92%; /*这个是按从左往右，从上往下的百分比位置进行调整*/
  padding-top: 20px;
  .menu-left {
    width: 220px;
    height: 254px;
    background-color: #ffffff;
    padding-top: 16px;
    .ger {
      font-size: 16px;
      margin-left: 22px;
      &:hover {
        color: #6EB400;
      }
    }
    .el-divider--horizontal {
      width: 86%;
      margin: 20px 13px;
      background-color: #d9d9d9;
    }
  }
  .menu-right {
    width: 928px;
    min-height: 546px;
    float: right;
    background-color: #ffffff;
    margin-bottom: 411px;
    margin-top: -270px;
  }
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
::v-deep.router-link-active {
  color: #6EB400;
}
</style>
