<template>
    <div class="headerss clear">
        <div class="w">
            <router-link to="/">
                <img src="@/assets/image/main/logo2.png" alt="" class="logo" />
            </router-link>
            <div class="info clear">
                <div class="xiang clear">
                    <img
                        v-if="form.avatar"
                        :src="`/jeecg-boot/${form.avatar}`"
                        alt="头像"
                        class="touxiang clear"
                    />
                    <img
                        v-else
                        src="@/assets/image/user/avatar.jpg"
                        alt="头像"
                        class="touxiang clear"
                    />
                    <div class="mzi clear">
                        <el-dropdown>
                            <span class="el-dropdown-link">
                                {{ form.username
                                }}<i
                                    class="el-icon-arrow-down el-icon--right"
                                ></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <router-link to="/user">
                                    <el-dropdown-item
                                        >基本信息</el-dropdown-item
                                    >
                                </router-link>
                                <router-link to="/user/mtravels"
                                    ><el-dropdown-item
                                        >我的游记</el-dropdown-item
                                    ></router-link
                                >
                                <router-link to="/user/writeadd">
                                    <el-dropdown-item
                                        >我要写游记</el-dropdown-item
                                    >
                                </router-link>
                                <el-dropdown-item @click.native="loginOut"
                                    >退出</el-dropdown-item
                                >
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { logout } from "@/api/login.js";
export default {
    created() {
        let userinfo = localStorage.getItem("userInfo");
        if (userinfo) {
            this.form = JSON.parse(userinfo);
        }
    },
    data() {
        return {
            form: {},
        };
    },
    methods: {
        loginOut() {
            const token = localStorage.getItem("token");
            let _this = this;
            logout(token).then((res) => {
                if (res.success) {
                    _this.$message.success(res.message);
                    localStorage.clear();
                    _this.$router.push("/");
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.headerss {
    background-color: #ffffff;
    padding-top: 28px;
    height: 56px;
    .logo {
        width: 276px;
        float: left;
        margin-top: -17px;
    }
    .info {
        float: right;
        margin-top: -5px;
        .touxiang {
            width: 34px;
            height: 34px;
            border-radius: 15px;
            margin-right: 10px;
        }
        .mzi {
            float: right;
            margin-bottom: 30px;
            margin-top: 10px;
        }
    }
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #ecf5ff;
    color: #6eb400;
}
</style>
